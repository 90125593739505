body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.markdown-content img {
  max-width: 100%;
  height: auto;
}

@media only screen and (max-width: 375px) {
  .container {
    /* 移动设备样式 */
    padding: 10px;
    font-size: 14px;
  }
}

@font-face {
  font-family: 'Montserrat';
  src: url(assets/ttf/Montserrat-Medium.ttf);
}

@font-face {
  font-family: 'Montserrat Black';
  src: url(assets/ttf/Montserrat-Black.ttf);
}

@font-face {
  font-family: 'Montserrat Light';
  src: url(assets/ttf/Montserrat-Light.ttf);
}

@font-face {
  font-family: 'Montserrat Bold';
  src: url(assets/ttf/Montserrat-Bold.ttf);
}

@font-face {
  font-family: 'canvas-kh';
  src: url(assets/ttf/canvas-kh.ttf);
}